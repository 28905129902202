// tokenService.js
import AuthenticationService from "@/services/AuthenticationService";

const tokenService = {
    isTokenExpired: (token) => {
        if (!token) {
            return true;
        }

        try {
            const decoded = JSON.parse(atob(token.split('.')[1]));
            return decoded.exp < Date.now() / 1000;
        } catch (e) {
            return false;
        }
    },
    getConfig: (router, store) => {
        const token = store.state.userToken;

        if (!token) {
            return null;
        }

        if (tokenService.isTokenExpired(token)) {
            AuthenticationService.logoutByTimeout(router, store, false);
            return null;
        }

        return {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
    },
}

export default tokenService;
