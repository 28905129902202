import PrimeVue from 'primevue/config';

// CSS
import 'primeicons/primeicons.css';

import Button from 'primevue/button';
import ButtonGroup from 'primevue/buttongroup';
import Card from 'primevue/card';
import Carousel from 'primevue/carousel';
import Galleria from 'primevue/galleria';
import Image from 'primevue/image';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import Checkbox from 'primevue/checkbox';
import InputText from 'primevue/inputtext';
import Password from "primevue/password";
import InputNumber from 'primevue/inputnumber';
import DatePicker from 'primevue/datepicker';
import Textarea from 'primevue/textarea';
import Breadcrumb from 'primevue/breadcrumb';
import Knob from 'primevue/knob';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import Row from 'primevue/row';
import Select from 'primevue/select';
import Calendar from 'primevue/calendar';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Paginator from 'primevue/paginator';
import Skeleton from 'primevue/skeleton';
import Menu from 'primevue/menu';
import Badge from 'primevue/badge';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import SplitButton from 'primevue/splitbutton';
import TabMenu from 'primevue/tabmenu';
import Tag from 'primevue/tag';
import Accordion from 'primevue/accordion';
import AccordionPanel from 'primevue/accordionpanel';
import AccordionHeader from 'primevue/accordionheader';
import AccordionContent from 'primevue/accordioncontent';
import RadioButton from 'primevue/radiobutton';
import Toast from 'primevue/toast';
import IconField from 'primevue/iconfield';
import DataView from 'primevue/dataview';
import InputIcon from 'primevue/inputicon';
import Fieldset from 'primevue/fieldset';
import InputMask from 'primevue/inputmask';
import OverlayPanel from 'primevue/overlaypanel';
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';
import InputOtp from 'primevue/inputotp';
import Divider from "primevue/divider";
import Message from "primevue/message";
import FileUpload from 'primevue/fileupload';
import Drawer from "primevue/drawer";
import Listbox from 'primevue/listbox';
import AutoComplete from 'primevue/autocomplete';
import PanelMenu from 'primevue/panelmenu';
import Panel from 'primevue/panel';

import Tabs from 'primevue/tabs';
import TabList from 'primevue/tablist';
import Tab from 'primevue/tab';
import TabPanels from 'primevue/tabpanels';
import TabPanel from 'primevue/tabpanel';
import SplitterPanel from 'primevue/splitterpanel';
import Splitter from 'primevue/splitter';

import 'vue-tel-input/vue-tel-input.css';

import VueTelInput from 'vue-tel-input';

// Services
import ToastService from 'primevue/toastservice';
import ConfirmationService from 'primevue/confirmationservice';

// Directives
import Tooltip from 'primevue/tooltip';
import Ripple from 'primevue/ripple';

// Custom component
import {StylePreset} from '@/plugins/preset.js';

//
export default function plugins(app) {
    app.use(PrimeVue, {
        ripple: true,
        theme: {
            preset: StylePreset, //Lara,
            options: {
                darkModeSelector: '', // disable auto dark mode
                cssLayer: {
                    name: 'primevue',
                    order: 'tailwind-base, primevue, tailwind-utilities'
                }
            }
        }
    })
        .use(ToastService)
        .use(ConfirmationService)
        .use(VueTelInput)
    ;

    app.directive('ripple', Ripple);
    app.directive('tooltip', Tooltip);

    app.component('Breadcrumb', Breadcrumb);
    app.component('Button', Button);
    app.component('ButtonGroup', ButtonGroup);
    app.component('Card', Card);
    app.component('Carousel', Carousel);
    app.component('Galleria', Galleria);
    app.component('Image', Image);
    app.component('InputGroup', InputGroup);
    app.component('InputGroupAddon', InputGroupAddon);
    app.component('Checkbox', Checkbox);
    app.component('InputText', InputText);
    app.component('InputNumber', InputNumber);
    app.component('DatePicker', DatePicker);
    app.component('Textarea', Textarea);
    app.component('Knob', Knob);
    app.component('DataTable', DataTable);
    app.component('Column', Column);
    app.component('ColumnGroup', ColumnGroup);
    app.component('Row', Row);
    app.component('Select', Select);
    app.component('Calendar', Calendar);
    app.component('ProgressBar', ProgressBar);
    app.component('ProgressSpinner', ProgressSpinner);
    app.component('Paginator', Paginator);
    app.component('Skeleton', Skeleton);
    app.component('Menu', Menu);
    app.component('Badge', Badge);
    app.component('Avatar', Avatar);
    app.component('AvatarGroup', AvatarGroup);
    app.component('SplitButton', SplitButton);
    app.component('TabMenu', TabMenu);
    app.component('Tag', Tag);
    app.component('Accordion', Accordion);
    app.component('AccordionPanel', AccordionPanel);
    app.component('AccordionHeader', AccordionHeader);
    app.component('AccordionContent', AccordionContent);
    app.component('RadioButton', RadioButton);
    app.component('Toast', Toast);
    app.component('IconField', IconField);
    app.component('Password', Password);
    app.component('DataView', DataView);
    app.component('InputIcon', InputIcon);
    app.component('Fieldset', Fieldset);
    app.component('InputMask', InputMask);
    app.component('OverlayPanel', OverlayPanel);
    app.component('ConfirmDialog', ConfirmDialog);
    app.component('Dialog', Dialog);
    app.component('Tabs', Tabs);
    app.component('TabList', TabList);
    app.component('Tab', Tab);
    app.component('TabPanels', TabPanels);
    app.component('TabPanel', TabPanel);
    app.component('SplitterPanel', SplitterPanel);
    app.component('Splitter', Splitter);
    app.component('InputOtp', InputOtp);
    app.component('Divider', Divider);
    app.component('Message', Message);
    app.component('FileUpload', FileUpload);
    app.component('Drawer', Drawer);
    app.component('Listbox', Listbox);
    app.component('AutoComplete', AutoComplete);
    app.component('VueTelInput', VueTelInput);
    app.component('PanelMenu', PanelMenu);
    app.component('Panel', Panel);
    // app.component('Editor', Editor);

}