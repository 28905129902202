import tokenService from "@/services/TokenService";
import axios from "axios";

const ContractService = {
    async listContracts(router, store) {
        const config = tokenService.getConfig(router, store);
        return await axios.get(process.env.VUE_APP_API_BASE_URL + '/api/contracts/list', config)
            .then(response => {
                return {
                    status: 'success',
                    contracts: response.data.contracts
                };
            })
            .catch(error => {
                console.error('Error loading contracts:', error.response || error);
                return {
                    status: 'error',
                    message: error.response.data.message
                };
            });

    }
};

export default ContractService;