import axios from "axios";
import AuthenticationService from "@/services/AuthenticationService";
import tokenService from "@/services/TokenService";

const TabService = {
    tabs: [],
    async listUserTabPermissions(store, router) {
        const backendUrl = process.env.VUE_APP_API_BASE_URL;
        const config = tokenService.getConfig(router, store);
        if (!config) {
            return [];
        }

        try {
            console.log('listUserTabPermissions');
            const response = await axios.get(backendUrl + '/api/permissions/tabs', config);
            this.tabs = response.data;
        } catch (error) {
            console.error('Error loading permissions!', error);
            AuthenticationService.logoutByTimeout(router, store, false);
        }

        if (!store.state.userName) {
            await AuthenticationService.getUserName(router, store);
        }

        return this.tabs;
    },
    async getFirstAvailableTabUrl(config, store, router) {
        if (!this.tabs) {
            await this.listUserTabPermissions(config, store, router);
        }

        if (!this.tabs) {
            return '/';
        }

        if (this.tabs.includes('dashboard')) {
            return '/dashboard';
        } else if (this.tabs.includes('schools')) {
            return '/schools';
        } else if (this.tabs.includes('users')) {
            return '/users';
        } else if (this.tabs.includes('upload')) {
            return '/upload';
        } else if (this.tabs.includes('api')) {
            return '/eligibility-check';
        } else if (this.tabs.includes('emails')) {
            return '/emails';
        } else if (this.tabs.includes('docs')) {
            return '/docs';
        }
        return '/';
    },
}


export default TabService;
