// import Vue from 'vue';
import { createApp } from 'vue';
import App from './App.vue';
import store from './store';
import { createRouter, createWebHistory } from 'vue-router';
import '@/assets/css/app.css';
import DDDocumentTab from "@/components/DDDocumentTab.vue";
import DDPrivacyNotice from "@/components/DDPrivacyNotice.vue";
import DDTermOfUse from "@/components/DDTermOfUse.vue";
import DDCookieNotice from "@/components/DDCookieNotice.vue";
import LoginPage from "@/pages/LoginPage.vue";
import plugins from "@/plugins";
import DashboardPage from "@/pages/DashboardPage.vue";
import AuthenticatedLayout from "@/layouts/AuthenticatedLayout.vue";
import UploadPage from "@/pages/UploadPage.vue";
import EligibilityCheckPage from "@/pages/EligibilityCheckPage.vue";
import UsersPage from "@/pages/UsersPage.vue";
import ProfilePage from "@/pages/ProfilePage.vue";
import SchoolsPage from "@/pages/SchoolsPage.vue";
import VerifyRegistrationPage from "@/pages/VerifyRegistrationPage.vue";


window.Vapor = require('laravel-vapor');


const routes = [
    {path: '/login', component: LoginPage, name: 'login'},
    {path: '/verify_registration', component: VerifyRegistrationPage, name: 'Verify Registration'},
    {
        path: '/', component: AuthenticatedLayout, meta: { requiresAuth: true },
        children: [
            {
                path: 'dashboard',
                component: DashboardPage,
                name: 'dashboard',
                meta: { requiresAuth: true }
            },
            {
                path: 'schools',
                component: SchoolsPage,
                name: 'schools',
                meta: { requiresAuth: true }
            },
            {
                path: 'users',
                component: UsersPage,
                name: 'users',
                meta: { requiresAuth: true }
            },
            {
                path: 'upload',
                component: UploadPage,
                name: 'upload',
                meta: { requiresAuth: true }
            },
            {
                path: 'eligibility-check',
                component: EligibilityCheckPage,
                name: 'eligibility-check',
                meta: {requiresAuth: true}
            },
            {
                path: 'docs',
                component: DDDocumentTab,
                name: 'docs',
                meta: { requiresAuth: true }
            },
            {
                path: 'profile',
                component: ProfilePage,
                name: 'profile',
                meta: { requiresAuth: true }
            },
            {
                path: '/privacy-notice',
                component: DDPrivacyNotice,
                name: 'Privacy Notice'
            },
            {
                path: '/terms-of-use',
                component: DDTermOfUse,
                name: 'Term Of Use'
            },
            {
                path: '/cookie-notice',
                component: DDCookieNotice,
                name: 'Cookie Notice'
            },
            {
                path: '/:catchAll(.*)',
                redirect: { name: 'dashboard' },

            },
        ]
    },
    {
        path: '/public/privacy-notice',
        component: DDPrivacyNotice,
        name: 'Public Privacy Notice'
    },
    {
        path: '/public/terms-of-use',
        component: DDTermOfUse,
        name: 'Public Term Of Use'
    },
    {
        path: '/public/cookie-notice',
        component: DDCookieNotice,
        name: 'Public Cookie Notice'
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in, token should be in vuex store
        // if not, redirect to login page.
        const token = store.getters.userToken;
        if (!token) {
            next(
                {
                    name: 'login',
                });
        } else {
            next();
        }
    } else {
        next(); // make sure to always call next()!
    }
});

const app = createApp(App);
plugins(app);
app.use(router);
app.use(store);
app.mount('#app');
