<script setup>

import {defineProps, inject, onMounted, ref, watch} from "vue";
import SchoolService from "@/services/SchoolService";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

//general
const store = useStore();
const router = useRouter();
const props = defineProps({
  showSchoolsTab: Boolean,
});

// eslint-disable-next-line no-unused-vars
const showToastMessage = inject("showToastMessage");

onMounted(() => {
  listSchoolDetail();
});

const listSchoolDetail = () => {
  SchoolService.listSchoolDetails(router, store).then((response) => {
    schools.value = response.schools.map(school => ({
      ...school,
      id: school.id,
      name: school.name,
      firstAlias: school.aliases?.[0]?.alias || '',
      aliasCount: school.aliases?.length || 0,
      firstReportingStream: school.reporting_streams?.[0]?.name || '',
      reportingStreamCount: school.reporting_streams?.length || 0,
      aliasList: school.aliases?.map(alias => alias.alias).join(', ') || '',
      reportingStreamList: school.reporting_streams?.map(stream => stream.name).join(', ') || '',
      contractCount: school.contracts?.length || 0,
      contractNumber: school.contracts?.[0]?.contract_number || '',
      contractTermStartDate: school.contracts?.[0]?.term_start_date || '',
      contractTermStartDateSort: school.contracts?.[0]?.term_start_date || '1900-01-01',
      contractTermEndDate: school.contracts?.[0]?.term_end_date || '',
      contractTermEndDateSort: school.contracts?.[0]?.term_end_date || '1900-01-01',
    }));
    table_loading.value = false;
  }).catch((error) => {
    console.error(error);
  });
};

// general data table
const schools = ref([]);
const filters = ref({
  global: {value: null},
  name: {value: null},
  alias: {value: null},
  contract_name: {value: null}
});

const selectedSchool = ref(null);


const table_loading = ref(true);

// drawer
const newSchoolDrawer = ref(false);
const detailSchoolDrawer = ref(false);

watch(detailSchoolDrawer, (newValue) => {
  if (!newValue) {
    contractMode.value = 'display';
    showNewAliasInput.value = false;
    showNewReportingStreamsInput.value = false;
    newAlias.value = '';
    newReportingStream.value = '';
  }
});

const toggleNewSchoolDrawer = () => {
  newSchoolDrawer.value = !newSchoolDrawer.value;
}

const selectSchool = (school) => {
  detailSchoolDrawer.value = true;
  selectedSchool.value = school;
}



const aliasesCollapsed = ref(false);
const aliasesIcon = ref('pi pi-chevron-down');
const aliasesEditingRows = ref([]);
const aliasEditLoading = ref(false);

const reportingStreamsCollapsed = ref(false);
const reportingStreamsIcon = ref('pi pi-chevron-down');
const reportingStreamsEditingRows = ref([]);
const reportingStreamEditLoading = ref(false);

const contractsCollapsed = ref(false);
const contractsIcon = ref('pi pi-chevron-down');
const contractMode = ref('display');

const selectedContract = ref(null);
const contractModeChange = (mode) => {
  contractMode.value = mode;
  if (mode === 'display') {
    selectedContract.value = null;
  } else {
    selectedContract.value = selectedSchool.value.contracts[0];
  }
};

const addContract = () => {
  contractMode.value = 'edit';
  selectedContract.value = {
    id: -1,
    contract_number: '',
    term_start_date: '',
    term_end_date: '',
  };
};

const saveContractLoading = ref(false);

const saveContract = async () => {
  saveContractLoading.value = true;
  if (selectedContract.value.id > 0) {
    const updateContractResponse = await SchoolService.updateContract(router, store, selectedSchool.value.id, selectedContract.value.id, selectedContract.value.term_start_date, selectedContract.value.term_end_date);
    if (updateContractResponse.status === 'success') {
      showToastMessage('success', 'School Updated', `Contract ${selectedContract.value.contract_number} has been saved for school ${selectedSchool.value.name}`);
      const updatedContract = updateContractResponse.contract;
      selectedSchool.value.contracts[0].term_start_date = new Date(updatedContract.term_start_date).toISOString().split('T')[0];
      selectedSchool.value.contracts[0].term_end_date = new Date(updatedContract.term_end_date).toISOString().split('T')[0];
      updateSchoolsTableWithoutReloading(updatedContract, 'contract');
    } else {
      showToastMessage('error', 'Error', updateContractResponse.message);
    }
  } else {
    const createContractResponse = await SchoolService.createContract(router, store, selectedSchool.value.id, selectedContract.value.contract_number, selectedContract.value.term_start_date, selectedContract.value.term_end_date);
    if (createContractResponse.status === 'success') {
      showToastMessage('success', 'School Updated', `Contract ${selectedContract.value.contract_number} has been saved for school ${selectedSchool.value.name}`);
      const createdContract = createContractResponse.contract;
      selectedSchool.value.contracts.push({
        id: createdContract.id,
        contract_number: createdContract.contract_number,
        term_start_date: createdContract.term_start_date,
        term_end_date: createdContract.term_end_date,
      });
      selectedSchool.value.contractCount = 1;

      updateSchoolsTableWithoutReloading(selectedSchool.value, 'contract');
    } else {
      showToastMessage('error', 'Error', createContractResponse.message);
    }
  }


  saveContractLoading.value = true;
  contractMode.value = 'display';
  selectedSchool.value.contracts[0] = selectedContract.value;
};


const toggleAliasesPanel = () => {
  aliasesCollapsed.value = !aliasesCollapsed.value;
  aliasesIcon.value = aliasesCollapsed.value ? 'pi pi-chevron-right' : 'pi pi-chevron-down';
};

const toggleReportingStreamsPanel = () => {
  reportingStreamsCollapsed.value = !reportingStreamsCollapsed.value;
  reportingStreamsIcon.value = reportingStreamsCollapsed.value ? 'pi pi-chevron-right' : 'pi pi-chevron-down';
};

const toggleContractsPanel = () => {
  contractsCollapsed.value = !contractsCollapsed.value;
  contractsIcon.value = contractsCollapsed.value ? 'pi pi-chevron-right' : 'pi pi-chevron-down';
};

const updateSchoolsTableWithoutReloading = (school, field) => {
  const relatedSchool = schools.value.find(school => school.id === selectedSchool.value.id);

  if (field === 'alias') {
    relatedSchool.firstAlias = selectedSchool.value.aliases[0].alias;
    relatedSchool.aliasCount = selectedSchool.value.aliases.length;
  } else if (field === 'reporting_stream') {
    relatedSchool.firstReportingStream = selectedSchool.value.reporting_streams[0].name;
    relatedSchool.reportingStreamCount = selectedSchool.value.reporting_streams.length;
  } else if (field === 'contract') {
    relatedSchool.contractNumber = selectedSchool.value.contracts[0].contract_number;
    relatedSchool.contractTermStartDate = selectedSchool.value.contracts[0].term_start_date;
    relatedSchool.contractTermEndDate = selectedSchool.value.contracts[0].term_end_date;
  }
};

const onRowEditSaveAlias = async (event) => {
  aliasEditLoading.value = true;

  event.newData.alias = event.newData.alias?.trim();

  //validate all names can only contains -_() and alphanumeric
  const nameRegex = /^[a-zA-Z0-9-_() ]+$/;
  if (!nameRegex.test(event.newData.alias)) {
    showToastMessage('error', 'Error', 'Alias can only contain alphanumeric characters, hyphen, underscore and parentheses');
    aliasEditLoading.value = false;
    return;
  }

  let {newData, index} = event;

  const editAliasResponse = await SchoolService.editAlias(router, store, newData.id, newData.alias);
  if (editAliasResponse.status === 'success') {
    showToastMessage('success', 'School Updated', `Alias ${newData.alias} has been saved for school ${selectedSchool.value.name}`);
    selectedSchool.value.aliases[index].alias = newData.alias;

    updateSchoolsTableWithoutReloading(selectedSchool.value, 'alias');
  } else {
    showToastMessage('error', 'Error', editAliasResponse.message);
  }

  aliasesEditingRows.value = [];
  aliasEditLoading.value = false;
};

const onRowEditSaveReportingStream = async (event) => {
  reportingStreamEditLoading.value = true;

  //validate all names can only contains -_() and alphanumeric
  const nameRegex = /^[a-zA-Z0-9-_() ]+$/;
  if (!nameRegex.test(event.newData.name)) {
    showToastMessage('error', 'Error', 'Reporting Stream name can only contain alphanumeric characters, hyphen, underscore and parentheses');
    reportingStreamEditLoading.value = false;
    return;
  }

  event.newData.name = event.newData.name?.trim();

  //check if reporting stream already exists from front data, reporting stream name should be unique inside one school
  if (selectedSchool.value.reporting_streams.find(stream => stream.name === event.newData.name)) {
    showToastMessage('error', 'Error', 'Reporting Stream Name is already taken');
    reportingStreamEditLoading.value = false;
    return;
  }

  let {newData, index} = event;

  const editReportingStreamResponse = await SchoolService.editReportingStream(router, store, newData.id, newData.name);
  if (editReportingStreamResponse.status === 'success') {
    showToastMessage('success', 'School Updated', `Reporting Stream ${newData.name} has been saved for school ${selectedSchool.value.name}`);
    selectedSchool.value.reporting_streams[index].name = newData.name;

    updateSchoolsTableWithoutReloading(selectedSchool.value, 'reporting_stream');
  } else {
    showToastMessage('error', 'Error', 'Failed to save reporting stream');
  }

  reportingStreamsEditingRows.value = [];
  reportingStreamEditLoading.value = false;
};

const showNewAliasInput = ref(false);
const saveAliasLoading = ref(false);
const newAlias = ref('');

const showNewReportingStreamsInput = ref(false);
const saveReportingStreamLoading = ref(false);
const newReportingStream = ref('');

const addReportingStream = () => {
  showNewReportingStreamsInput.value = true;
};

const addAlias = () => {
  showNewAliasInput.value = true;
};

const saveAlias = async () => {
  saveAliasLoading.value = true;

  newAlias.value = newAlias.value.trim();

  const nameRegex = /^[a-zA-Z0-9-_() ]+$/;
  if (!nameRegex.test(newAlias.value)) {
    showToastMessage('error', 'Error', 'Alias can only contain alphanumeric characters, hyphen, underscore and parentheses');
    saveAliasLoading.value = false;
    return;
  }

  const createAliasResponse = await SchoolService.createAlias(router, store, selectedSchool.value.id, newAlias.value);
  if (createAliasResponse.status === 'success') {
    console.log("createAliasResponse", createAliasResponse);
    showToastMessage('success', 'School Updated', `Alias ${newAlias.value} has been saved for school ${selectedSchool.value.name}`);
    selectedSchool.value.aliases.push({
      id: createAliasResponse.id,
      alias: createAliasResponse.alias,
    });
    updateSchoolsTableWithoutReloading(selectedSchool.value, 'alias');
  } else {
    showToastMessage('error', 'Error', createAliasResponse.message);
  }

  saveAliasLoading.value = false;
  newAlias.value = '';
  showNewAliasInput.value = false;
};

const saveReportingStream = async () => {
  saveReportingStreamLoading.value = true;

  newReportingStream.value = newReportingStream.value.trim();

  const nameRegex = /^[a-zA-Z0-9-_() ]+$/;
  if (!nameRegex.test(newReportingStream.value)) {
    showToastMessage('error', 'Error', 'Reporting Stream name can only contain alphanumeric characters, hyphen, underscore and parentheses');
    saveReportingStreamLoading.value = false;
    return;
  }

  //check if reporting stream already exists from front data, reporting stream name should be unique inside one school
  if (selectedSchool.value.reporting_streams.find(stream => stream.name === newReportingStream.value)) {
    showToastMessage('error', 'Error', 'Reporting Stream Name is already taken');
    saveReportingStreamLoading.value = false;
    return;
  }

  const createReportingStreamResponse = await SchoolService.createReportingStream(router, store, selectedSchool.value.id, newReportingStream.value);
  if (createReportingStreamResponse.status === 'success') {
    showToastMessage('success', 'School Updated', `Reporting Stream ${newReportingStream.value} has been saved for school ${selectedSchool.value.name}`);
    const createdReportingStream = createReportingStreamResponse.reporting_stream;
    selectedSchool.value.reporting_streams.push({
      id: createdReportingStream.id,
      name: createdReportingStream.name,
    });

    updateSchoolsTableWithoutReloading(selectedSchool.value, 'reporting_stream');
  } else {
    //map message
    let errorMessage = '';
    if ("Reporting Stream already exists" === createReportingStreamResponse.message) {
      errorMessage = 'Reporting Stream Name already taken';
    } else {
      errorMessage = 'Failed to add reporting stream';
    }
    showToastMessage('error', 'Error', errorMessage);
    saveReportingStreamLoading.value = false;
  }

  newReportingStream.value = '';
  showNewReportingStreamsInput.value = false;
  saveReportingStreamLoading.value = false;

};

const cancelNewAlias = () => {
  showNewAliasInput.value = false;
  newAlias.value = '';
};

const cancelNewReportingStream = () => {
  showNewReportingStreamsInput.value = false;
  newReportingStream.value = '';
};

const newSchool = ref({
  name: '',
  aliases: [],
  reporting_streams: [],
  contracts: null,
});


const errorMessages = ref({
  schoolName: '',
  contractNumber: '',
  startDate: '',
  endDate: '',
});

watch(() => newSchool.value.name, (newValue) => {
  if (newValue !== '') {
    errorMessages.value.schoolName = '';
  }
});

const disableStartAndEndDates = ref(false);
watch(() => newSchool.value.contracts?.contract_number, (newValue) => {
  disableStartAndEndDates.value = newValue === '';
  errorMessages.value.contractNumber = '';
});

watch(() => newSchool.value.contracts?.term_start_date, (newValue) => {
  if (newValue !== '') {
    errorMessages.value.startDate = '';
  }
});

watch(() => newSchool.value.contracts?.term_end_date, (newValue) => {
  if (newValue !== '') {
    errorMessages.value.endDate = '';
  }
});

const newSchoolLoading = ref(false);

const addNewTempAlias = () => {
  newSchool.value.aliases.push('');
};


const deleteNewTempAlias = (index) => {
  newSchool.value.aliases.splice(index, 1);
};

const addNewTempReportingStream = () => {
  newSchool.value.reporting_streams.push('');
};

const deleteNewTempReportingStream = (index) => {
  newSchool.value.reporting_streams.splice(index, 1);
};

const addNewTempContract = () => {
  newSchool.value.contracts = {
    contract_number: '',
    term_start_date: '',
    term_end_date: '',
  };
};

// eslint-disable-next-line no-unused-vars
const deleteNewTempContract = () => {
  newSchool.value.contracts = null;
};

const cancelNewSchool = () => {
  newSchoolDrawer.value = false;
  showNewSchoolAliasPanel.value = false;
  showNewSchoolReportingStreamPanel.value = false;
  showNewSchoolContractsPanel.value = false;

  newSchool.value = {
    name: '',
    aliases: [],
    reporting_streams: [],
    contracts: null,
  };
};

const addNewSchool = async () => {
  newSchoolLoading.value = true;

  if (newSchool.value.name === '') {
    errorMessages.value.schoolName = 'School name is required';
    newSchoolLoading.value = false;
    return;
  }

  if (schools.value.find(school => school.name === newSchool.value.name)) {
    showToastMessage('error', 'Error', 'School Name is already taken');
    newSchoolLoading.value = false;
    return;
  }

  let contractNumberError = false;
  let startDateError = false;
  let endDateError = false;

  if (
      newSchool.value.contracts?.term_start_date instanceof Date
      || newSchool.value.contracts?.term_end_date instanceof Date
  ) {
    if (!newSchool.value.contracts?.contract_number?.trim?.()) {
      errorMessages.value.contractNumber = 'Contract Number is required when you have Start Date or End Date';
      contractNumberError = true;
    }
  }


  if (newSchool.value.contracts && newSchool.value.contracts.contract_number) {
    if (newSchool.value.contracts.term_start_date === '') {
      errorMessages.value.startDate = 'Start Date is required';
      startDateError = true;
    }

    if (newSchool.value.contracts.term_end_date === '') {
      errorMessages.value.endDate = 'End Date is required';
      newSchoolLoading.value = false;
      return;
    }

    if (newSchool.value.contracts.term_end_date < newSchool.value.contracts.term_start_date) {
      errorMessages.value.endDate = 'End Date must be after Start Date';
      newSchoolLoading.value = false;
      return;
    }

    //end date should after current date, also should not be more than 366 days from start date
    const currentDate = new Date();
    const startDate = new Date(newSchool.value.contracts.term_start_date);
    const endDate = new Date(newSchool.value.contracts.term_end_date);
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (endDate < currentDate) {
      errorMessages.value.endDate = 'End Date should be after current date';
      newSchoolLoading.value = false;
      return;
    }

    if (diffDays > 366) {
      errorMessages.value.endDate = 'End Date should not be more than 366 days from Start Date';
      newSchoolLoading.value = false;
      return;
    }
  }

  if (contractNumberError || startDateError || endDateError) {
    newSchoolLoading.value = false;
    return;
  }

  if (newSchool.value.reporting_streams.length === 1 && newSchool.value.reporting_streams[0] === '') {
    newSchool.value.reporting_streams = [];
  }

  if (newSchool.value.aliases.length === 1 && newSchool.value.aliases[0] === '') {
    newSchool.value.aliases = [];
  }

  if (newSchool.value.contracts && newSchool.value.contracts.contract_number === '') {
    newSchool.value.contracts = null;
  }

  //validate all names can only contains -_() and alphanumeric
  const nameRegex = /^[a-zA-Z0-9-_() ]+$/;
  const validSchooName = ref(true);
  const validAliases = ref(true);
  const validReportingStreams = ref(true);
  const validContracts = ref(true);
  if (!nameRegex.test(newSchool.value.name)) {
    showToastMessage('error', 'Error', 'School name can only contain alphanumeric characters, hyphen, underscore and parentheses');
    newSchoolLoading.value = false;
    validSchooName.value = false;
  }

  for (let i = 0; i < newSchool.value.aliases.length; i++) {
    newSchool.value.aliases[i] = newSchool.value.aliases[i].trim();
    if (newSchool.value.aliases[i] === '') {
      newSchool.value.aliases.splice(i, 1);
      i--;
      continue;
    }

    if (!nameRegex.test(newSchool.value.aliases[i])) {
      showToastMessage('error', 'Error', 'Alias can only contain alphanumeric characters, hyphen, underscore and parentheses');
      newSchoolLoading.value = false;
      validAliases.value = false;
    }
  }

  for (let i = 0; i < newSchool.value.reporting_streams.length; i++) {
    newSchool.value.reporting_streams[i] = newSchool.value.reporting_streams[i].trim();
    if (newSchool.value.reporting_streams[i] === '') {
      newSchool.value.reporting_streams.splice(i, 1);
      i--;
      continue;
    }
    if (!nameRegex.test(newSchool.value.reporting_streams[i])) {
      showToastMessage('error', 'Error', 'Reporting Stream can only contain alphanumeric characters, hyphen, underscore and parentheses');
      newSchoolLoading.value = false;
      validReportingStreams.value = false;
    }
  }

  // reporting streams should be unique within this school
  const reportingStreamSet = new Set(newSchool.value.reporting_streams);
  if (reportingStreamSet.size !== newSchool.value.reporting_streams.length) {
    showToastMessage('error', 'Error', 'Reporting Stream Names should be unique per school');
    newSchoolLoading.value = false;
    validReportingStreams.value = false;
  }

  if (newSchool.value.contracts) {
    if (!nameRegex.test(newSchool.value.contracts.contract_number)) {
      showToastMessage('error', 'Error', 'Contract number can only contain alphanumeric characters, hyphen, underscore and parentheses');
      newSchoolLoading.value = false;
      validContracts.value = false;
    }
  }

  if (!validSchooName.value || !validAliases.value || !validReportingStreams.value || !validContracts.value) {
    return;
  }

  const newSchoolResponse = await SchoolService.addNewSchoolWithAssociatedData(router, store, newSchool.value.name, newSchool.value.reporting_streams, newSchool.value.aliases, newSchool.value.contracts);
  if (newSchoolResponse.status === 'success') {
    showToastMessage('success', 'School Created', `School ${newSchool.value.name} has been created successfully`);
    showNewSchoolAliasPanel.value = false;
    showNewSchoolReportingStreamPanel.value = false;
    showNewSchoolContractsPanel.value = false;
    listSchoolDetail();
    newSchool.value = {
      name: '',
      aliases: [],
      reporting_streams: [],
      contracts: null,
    };
    newSchoolDrawer.value = false;
  } else {
    showToastMessage('error', 'Error', newSchoolResponse.message);
    newSchoolDrawer.value = true;
  }

  newSchoolLoading.value = false;
};

const showNewSchoolAliasPanel = ref(false);
const showNewSchoolReportingStreamPanel = ref(false);
const showNewSchoolContractsPanel = ref(false);

const toggleNewSchoolAliasPanel = () => {
  showNewSchoolAliasPanel.value = !showNewSchoolAliasPanel.value;
  if (showNewSchoolAliasPanel.value && newSchool.value.aliases.length === 0) {
    addNewTempAlias();
  }
};

const toggleNewSchoolReportingStreamPanel = () => {
  showNewSchoolReportingStreamPanel.value = !showNewSchoolReportingStreamPanel.value;
  if (showNewSchoolReportingStreamPanel.value && newSchool.value.reporting_streams.length === 0) {
    addNewTempReportingStream();
  }
};

const toggleNewSchoolContractsPanel = () => {
  showNewSchoolContractsPanel.value = !showNewSchoolContractsPanel.value;
  if (showNewSchoolContractsPanel.value && !newSchool.value.contracts) {
    addNewTempContract();
  }
};

</script>

<template>
  <div v-if="props.showSchoolsTab">
    <h1 class="text-3xl font-bold py-8 text-primaryBlue flex items-center">
      Schools Information
      <Button class="py-1 ml-8 flex items-center" @click="toggleNewSchoolDrawer">
        <i class="pi pi-plus mr-2"></i>
        <p>New School</p>
      </Button>
      <Drawer v-model:visible="newSchoolDrawer" position="right" class="w-[500px]">
        <template #header>
          <h1 class="text-2xl justify-end"></h1>
        </template>
        <h1 class="font-bold text-2xl mt-8 mb-6 px-4">New School</h1>
        <div class="flex flex-col gap-2 px-4">
          <label for="newSchoolName">School Name</label>
          <InputText id="newSchoolName" v-model="newSchool.name"/>
          <Message severity="error" v-if="errorMessages.schoolName"> {{ errorMessages.schoolName }}</Message>
          <div id="new-school-alias">
            <div id="new-school-alias-button-area"
                 class="relative w-full h-full">
              <Button
                  class="bg-transparent border-none pl-0 absolute transition-transform duration-500 ease-in-out"
                  :class="showNewSchoolAliasPanel ? 'translate-x-[19rem] p-button-danger' : 'translate-x-0 '"
                  :icon="showNewSchoolAliasPanel ? 'pi pi-times' : 'pi pi-plus'"
                  iconPos="left"
                  :label="showNewSchoolAliasPanel ? 'Hide All Alias' : 'Add Alias'"
                  outlined
                  @click="toggleNewSchoolAliasPanel"/>
            </div>
            <Panel v-if="showNewSchoolAliasPanel">
              <template #header>
                <div class="panel-header ">
                  <div>Aliases</div>
                </div>
              </template>
              <div id="new-alias-input-field" class="w-full pl-4 flex flex-col items-center">
                <div v-for="(alias, index) in newSchool.aliases" :key="index"
                     class="w-full flex items-center justify-between my-2">
                  <InputText type="text" v-model="newSchool.aliases[index]" class="w-64"/>

                  <Button
                      icon="pi pi-trash"
                      class="p-button-danger border-none"
                      outlined
                      rounded
                      @click="deleteNewTempAlias(index)"
                  />
                </div>
              </div>
              <Button
                  class="bg-transparent border-none pl-0"
                  icon="pi pi-plus"
                  iconPos="left"
                  label="Add another Alias"
                  outlined
                  @click="addNewTempAlias"/>
            </Panel>
          </div>

          <div id="new-school-reporting-stream">
            <div id="new-school-reporting-stream-button-area"
                 class="relative w-full h-full">
              <Button
                  class="bg-transparent border-none pl-0 absolute transition-transform duration-500 ease-in-out"
                  :class="showNewSchoolReportingStreamPanel ? 'translate-x-[13rem] p-button-danger' : 'translate-x-0 '"
                  :icon="showNewSchoolReportingStreamPanel ? 'pi pi-times' : 'pi pi-plus'"
                  iconPos="left"
                  :label="showNewSchoolReportingStreamPanel ? 'Hide All Reporting Stream' : 'Add Reporting Stream'"
                  outlined
                  @click="toggleNewSchoolReportingStreamPanel"/>
            </div>
            <Panel v-if="showNewSchoolReportingStreamPanel">
              <template #header>
                <div class="panel-header ">
                  <div>Reporting Streams</div>
                </div>
              </template>
              <div id="new-alias-input-field" class="w-full pl-4 flex flex-col items-center">
                <div v-for="(reporting_stream, index) in newSchool.reporting_streams" :key="index"
                     class="w-full flex items-center justify-between my-2">
                  <InputText type="text" v-model="newSchool.reporting_streams[index]" class="w-64"/>

                  <Button
                      icon="pi pi-trash"
                      class="p-button-danger border-none"
                      outlined
                      rounded
                      @click="deleteNewTempReportingStream(index)"
                  />
                </div>
              </div>
              <Button
                  class="bg-transparent border-none pl-0"
                  icon="pi pi-plus"
                  iconPos="left"
                  label="Add another Reporting Stream"
                  outlined
                  @click="addNewTempReportingStream"/>
            </Panel>
          </div>

          <div id="new-school-contracts">
            <div id="new-school-contracts-button-area"
                 class="relative w-full h-full">
              <Button
                  class="bg-transparent border-none pl-0 absolute transition-transform duration-500 ease-in-out"
                  :class="showNewSchoolContractsPanel ? 'translate-x-[13rem] p-button-danger' : 'translate-x-0 '"
                  :icon="showNewSchoolContractsPanel ? 'pi pi-times' : 'pi pi-plus'"
                  iconPos="left"
                  :label="showNewSchoolContractsPanel ? 'Hide All Contract' : 'Add Contract'"
                  outlined
                  @click="toggleNewSchoolContractsPanel"/>
            </div>
            <Panel v-if="showNewSchoolContractsPanel">
              <template #header>
                <div class="panel-header ">
                  <div>Contracts</div>
                </div>
              </template>
              <div v-if="newSchool.contracts" id="new-contract-input-field" class="w-full pl-4 space-y-2">
                <div class="flex items-center justify-between w-full">
                  <InputText v-model="newSchool.contracts.contract_number" placeholder="Contract Number" class="w-64"/>
<!--                  <Button-->
<!--                      icon="pi pi-trash"-->
<!--                      class="p-button-danger border-none"-->
<!--                      outlined-->
<!--                      rounded-->
<!--                      @click="deleteNewTempContract"-->
<!--                  />-->
                </div>

                <Message severity="error" v-if="errorMessages.contractNumber" class="w-64"> {{errorMessages.contractNumber}} </Message>

                <div>
                  <DatePicker v-model="newSchool.contracts.term_start_date" class="w-64" placeholder="Start Date"
                              dateFormat="yy-mm-dd" :disabled="disableStartAndEndDates"/>
                  <Message severity="error" v-if="errorMessages.startDate"> {{ errorMessages.startDate }}</Message>
                </div>

                <div>
                  <DatePicker v-model="newSchool.contracts.term_end_date" class="w-64" placeholder="End Date"
                              dateFormat="yy-mm-dd" :disabled="disableStartAndEndDates"/>
                  <Message severity="error" v-if="errorMessages.endDate"> {{ errorMessages.endDate }}</Message>
                </div>
              </div>
              <!--              <Button-->
              <!--                  class="bg-transparent border-none pl-0"-->
              <!--                  icon="pi pi-plus"-->
              <!--                  iconPos="left"-->
              <!--                  label="Add Contract"-->
              <!--                  outlined-->
              <!--                  @click="addNewTempContract" />-->
            </Panel>
            <div id="create-school-button-area" class="my-8 flex justify-between items-center">
              <Button
                  class="font-bold ml-24 px-6"
                  label="Cancel"
                  outlined
                  @click="cancelNewSchool"/>

              <Button
                  class="font-bold px-6"
                  icon="pi pi-check"
                  iconPos="left"
                  label="Create School"
                  :loading="newSchoolLoading"
                  @click="addNewSchool"/>
            </div>
          </div>
        </div>
      </Drawer>
      <Drawer v-model:visible="detailSchoolDrawer" position="right" class="w-[500px]">
        <template #header>
          <h1 class="text-2xl justify-end"></h1>
        </template>
        <h1 class="font-bold text-2xl mt-8 mb-6">{{ selectedSchool.name }}</h1>

        <Panel toggleable :collapsed="aliasesCollapsed">
          <template #header>
            <div class="panel-header flex items-center justify-between">
              <div class="expand-button-container" @click="toggleAliasesPanel">
                <Button :icon=aliasesIcon class="p-button-text"/>
              </div>

              <div>Aliases</div>
            </div>
          </template>

          <template #toggleicon>
            <Tag severity="danger" :value="selectedSchool.aliasCount" class="bg-red-300 text-red-900"></Tag>
          </template>

          <div>
            <DataTable v-model:editingRows="aliasesEditingRows"
                       :value="selectedSchool.aliases"
                       editMode="row"
                       dataKey="id"
                       @row-edit-save="onRowEditSaveAlias"
                       id="aliasesTable"
            >

              <Column field="alias" class="border-none py-1 w-72">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" fluid/>
                </template>
              </Column>

              <Column :rowEditor="true" bodyStyle="text-align:right" class="border-none pr-0 py-1"></Column>

              <template #footer>
                <div id="new-alias-input-field" class="w-full pl-4" v-if="showNewAliasInput">
                  <InputText type="text" v-model="newAlias" class="w-64"/>
                  <Button
                      icon="pi pi-check"
                      class="p-button-success border-none ml-12"
                      outlined
                      rounded
                      :loading="saveAliasLoading"
                      @click="saveAlias"
                  />
                  <Button
                      icon="pi pi-trash"
                      class="p-button-danger border-none"
                      outlined
                      rounded
                      @click="cancelNewAlias"
                  />
                </div>

                <Button
                    class="bg-transparent border-none pl-0"
                    icon="pi pi-plus"
                    iconPos="left"
                    label="Add another Alias"
                    outlined
                    @click="addAlias"/>
              </template>
            </DataTable>
          </div>
        </Panel>

        <Panel toggleable :collapsed="reportingStreamsCollapsed">
          <template #header>
            <div class="panel-header flex items-center justify-between">
              <div class="expand-button-container" @click="toggleReportingStreamsPanel">
                <Button :icon=reportingStreamsIcon class="p-button-text"/>
              </div>

              <div>Reporting Streams</div>
            </div>
          </template>

          <template #toggleicon>
            <Tag severity="danger" :value="selectedSchool.reportingStreamCount" class="bg-red-300 text-red-900"></Tag>
          </template>

          <div>
            <DataTable v-model:editingRows="reportingStreamsEditingRows"
                       :value="selectedSchool.reporting_streams"
                       editMode="row"
                       dataKey="id"
                       @row-edit-save="onRowEditSaveReportingStream"
                       id="reportingStreamsTable"
            >

              <Column field="name" class="border-none py-1 w-72">
                <template #editor="{ data, field }">
                  <InputText v-model="data[field]" fluid/>
                </template>
              </Column>

              <Column :rowEditor="true" bodyStyle="text-align:right" class="border-none pr-0 py-1"></Column>

              <template #footer>
                <div id="new-alias-input-field" class="w-full pl-4" v-if="showNewReportingStreamsInput">
                  <InputText type="text" v-model="newReportingStream" class="w-64"/>
                  <Button
                      icon="pi pi-check"
                      class="p-button-success border-none ml-12"
                      outlined
                      rounded
                      :loading="saveReportingStreamLoading"
                      @click="saveReportingStream"
                  />
                  <Button
                      icon="pi pi-trash"
                      class="p-button-danger border-none"
                      outlined
                      rounded
                      @click="cancelNewReportingStream"
                  />
                </div>

                <Button
                    class="bg-transparent border-none pl-0"
                    icon="pi pi-plus"
                    iconPos="left"
                    label="Add another Reporting Stream"
                    outlined
                    @click="addReportingStream"/>
              </template>
            </DataTable>
          </div>
        </Panel>

        <Panel toggleable :collapsed="contractsCollapsed">
          <template #header>
            <div class="panel-header flex items-center justify-between">
              <div class="expand-button-container" @click="toggleContractsPanel">
                <Button :icon=contractsIcon class="p-button-text"/>
              </div>

              <div>Contracts</div>
            </div>
          </template>

          <template #toggleicon>
            <Tag severity="danger" :value="selectedSchool.contractCount" class="bg-red-300 text-red-900"></Tag>
          </template>

          <div v-if="selectedSchool.contractCount > 0 || contractMode === 'edit'">
            <div class="flex justify-between items-center">
              <div class="flex-1">
                <div v-if="contractMode === 'display'" class="w-full pl-4">
                  {{ selectedSchool.contractNumber }}
                </div>
                <div v-else-if="contractMode === 'edit'" class="w-full pl-4">
                  <InputText v-model="selectedContract.contract_number" placeholder="Contract Number" class="w-64"
                             :disabled="selectedSchool.contractCount > 0"/>
                </div>
              </div>
              <div id="contract-right" class="flex space-x-2">
                <Button
                    v-if="contractMode === 'display'"
                    icon="pi pi-pencil"
                    class="border-none"
                    outlined
                    rounded
                    @click="contractModeChange('edit')"
                />
                <Button
                    v-else-if="contractMode === 'edit'"
                    icon="pi pi-times"
                    class="border-none"
                    outlined
                    rounded
                    @click="contractModeChange('display')"
                />
              </div>
            </div>

            <div v-if="contractMode === 'display'" class="w-full pl-4 mt-2">
              {{ selectedSchool.contractTermStartDate }}
            </div>
            <div v-else-if="contractMode === 'edit'" class="w-full pl-4 mt-2">
              <DatePicker v-model="selectedContract.term_start_date"
                          class="w-64"
                          dateFormat="yy-mm-dd"
                          placeholder="Start Date"/>
            </div>

            <div v-if="contractMode === 'display'" class="w-full mt-2 pl-4">
              {{ selectedSchool.contractTermEndDate }}
            </div>
            <div v-else-if="contractMode === 'edit'" class="w-full mt-2 pl-4">
              <DatePicker v-model="selectedContract.term_end_date"
                          class="w-64"
                          dateFormat="yy-mm-dd"
                          placeholder="End Date"/>
            </div>

            <div v-if="contractMode === 'edit'">
              <Button icon="pi pi-check"
                      class="border-none mt-2 ml-4"
                      label="Save Contract"
                      @click="saveContract"/>
            </div>
          </div>
          <div v-else>
            <Button
                class="bg-transparent border-none pl-0"
                icon="pi pi-plus"
                iconPos="left"
                label="Add new Contract"
                outlined
                @click="addContract"/>
          </div>

        </Panel>

      </Drawer>
    </h1>

    <div class="card">
      <DataTable
          id="schoolsTable"
          v-model:filters="filters"
          :value="schools"
          removableSort
          rowHover
          stripedRows
          highlightOnSelect
          :rows="10"
          dataKey="id"
          filterDisplay="row"
          :loading="table_loading"
          :globalFilterFields="['name', 'firstAlias', 'firstReportingStream', 'aliasList', 'reportingStreamList', 'contractNumber', 'contractStartDate', 'contractEndDate']"
          paginator
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
          :rowsPerPageOptions="[10, 20, 30]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} schools"
      >
        <template #header>
          <div class="flex justify-start w-[450px]">
            <IconField class="w-full">
              <InputIcon>
                <i class="pi pi-search"/>
              </InputIcon>
              <InputText
                  class="w-full"
                  v-model="filters['global'].value"
                  placeholder="Search"
              />
            </IconField>
          </div>
        </template>
        <template #empty> No schools found.</template>
        <template #loading> Loading schools data. Please wait.</template>

<!--        <Column field="id" header="Id" :sortable="true" style="min-width: 4rem">-->
<!--          <template #body="{ data }">-->
<!--            {{ data.id }}-->
<!--          </template>-->
<!--        </Column>-->

        <Column field="name" header="Name" :sortable="true" style="min-width: 12rem">
          <template #body="{ data }">
            {{ data.name }}
          </template>
        </Column>

        <Column
            field="firstAlias"
            header="Aliases"
            :sortable="true"
            style="min-width: 12rem"
        >
          <template #body="{ data }">
            <Tag
                v-if="data.aliasCount > 0"
                severity="secondary"
                :value="data.firstAlias"
                class="bg-gray-300 text-black mr-0.5"
            ></Tag>
            {{ data.aliasCount > 1 ? `+${data.aliasCount - 1}` : '' }}
          </template>
        </Column>


        <Column
            field="firstReportingStream"
            header="Reporting Streams"
            :sortable="true"
            style="min-width: 12rem"
        >
          <template #body="{ data }">
            <Tag
                v-if="data.reportingStreamCount > 0"
                severity="secondary"
                :value="data.firstReportingStream"
                class="bg-gray-300 text-black mr-0.5"
            ></Tag>
            {{ data.reportingStreamCount > 1 ? `+${data.reportingStreamCount - 1}` : '' }}
          </template>
        </Column>

        <Column
            field="contractNumber"
            header="Contracts"
            :sortable="true"
            style="min-width: 12rem"
        >
          <template #body="{ data }">
            {{ data?.contracts?.[0]?.contract_number || '' }}
          </template>
        </Column>

        <Column
            field="contractStartDate"
            header="Contract Start Date"
            :sortable="true"
            sort-field="contractTermStartDateSort"
            style="min-width: 12rem"
        >
          <template #body="{ data }">
            {{ data?.contractTermStartDate }}
          </template>
        </Column>

        <Column
            field="contractEndDate"
            header="Contract End Date"
            :sortable="true"
            sort-field="contractTermEndDateSort"
            style="min-width: 12rem">
          <template #body="{ data }">
            {{ data?.contractTermEndDate }}
          </template>
        </Column>

        <Column class="w-24 !text-end">
          <template #body="{ data }">
            <Button icon="pi pi-eye" @click="selectSchool(data)" severity="secondary"
                    class="bg-transparent border-none"></Button>
          </template>
        </Column>
      </DataTable>

    </div>
  </div>
</template>

<style>
#aliasesTable .p-datatable-table-container .p-datatable-table .p-datatable-thead {
  display: none;
}

#aliasesTable .p-datatable-footer {
  background-color: transparent;
  border: none;
  padding-left: 0;
  padding-right: 0;
}

#reportingStreamsTable .p-datatable-table-container .p-datatable-table .p-datatable-thead {
  display: none;
}

#reportingStreamsTable .p-datatable-footer {
  background-color: transparent;
  border: none;
  padding-left: 0;
  padding-right: 0;
}

#schoolsTable .p-paginator {
  justify-content: flex-end;
  padding-right: 30px;
}
</style>